import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ValidationRules} from '../../definitions/Validation';

export const useValidation = () => {
  const {t} = useTranslation();

  const validateEmail = useMemo(
    () => ({
      regex: ValidationRules.EmailRegExp,
      message: t('FormValidation.email'),
    }),
    [t],
  );

  const validatePhoneNumber = useMemo(
    () => ({
      regex: ValidationRules.PhoneNumberRegExp,
      message: t('FormValidation.phoneNumber'),
    }),
    [t],
  );

  const validateAlphabetic = useMemo(
    () => ({
      regex: ValidationRules.AlphabeticRegExp,
      message: t('FormValidation.alphabetic'),
    }),
    [t],
  );

  const validateAlphanumeric = useMemo(
    () => ({
      regex: ValidationRules.AlphanumericRegExp,
      message: t('FormValidation.alphanumeric'),
    }),
    [t],
  );

  return {
    validateEmail,
    validatePhoneNumber,
    validateAlphabetic,
    validateAlphanumeric,
  };
};
