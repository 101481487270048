import {useMemo} from 'react';
import {useTheme} from '@mui/material';
import {AppointmentSlotStyleProps} from '../../components/molecules/AppointmentSlot/interfaces';

export const useAppointmentColors = ({
  appointmentStatus,
  appointmentType,
  meetingStatus,
  isPadding = false,
}: AppointmentSlotStyleProps) => {
  const theme = useTheme();

  const colors = useMemo(
    () => ({
      ONLINE: theme.palette.online,
      PERSON: theme.palette.presence,
    }),
    [theme.palette],
  );

  if (!appointmentType || isPadding) {
    return theme.palette.white;
  }

  if (appointmentStatus === 'RESCHEDULED') {
    return theme.palette.pending;
  }

  if (meetingStatus === 'MEETING_STARTED') {
    return theme.palette.started;
  }

  return colors[appointmentType];
};
