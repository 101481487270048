import {useEffect} from 'react';
import axios from 'axios';
import {useTranslation} from 'react-i18next';
import {ServerErrorMessage} from '../../definitions/Server';
import {useToast} from '../../hooks';
import {useAuthentication} from '../authentication';

const AxiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const useAPI = () => {
  const {isLoggedIn, token, msToken, logout, refreshLogin} =
    useAuthentication();
  const {t} = useTranslation();
  const {showErrorToast} = useToast();

  useEffect(() => {
    if (isLoggedIn && token) {
      AxiosApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      AxiosApi.defaults.headers.common['MS-Authorization'] = msToken;
    } else {
      AxiosApi.defaults.headers.common['Authorization'] = null;
      AxiosApi.defaults.headers.common['MS-Authorization'] = null;
    }
  }, [isLoggedIn, msToken, token]);

  useEffect(() => {
    AxiosApi.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        const errorStatus = error?.response?.status;
        const errorMessage = error.response.data?.message as ServerErrorMessage;

        if (errorStatus === 401) {
          if (errorMessage === ServerErrorMessage.E_EXPIRED_TOKEN) {
            refreshLogin();
          } else {
            logout();
            showErrorToast({
              message: t('Authentication.authError'),
            });
          }
        }
        return Promise.reject(error);
      },
    );
  }, [logout, t, showErrorToast, refreshLogin]);

  return {
    api: AxiosApi,
  };
};

export const _api = AxiosApi;
