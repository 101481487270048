/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
export enum ValidationRules {
  FiscalCodeRegExp = '/^[A-Z]{6}[0-9]{2}[ABCDEHLMPRST]{1}[0-9]{2}([A-Z]{1}[0-9]{3})[A-Z]{1}$/i',
  EmailRegExp = '/^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$/',
  PasswordRegExp = '/^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])[\\w~@#$%^&*+=`|{}:;!.?\\"()\\[\\]-]{8,}/', // 1 lower character, 1 upper character, 1 number, 8 characters at least, special character optional
  /** Matches 10-digits phone numbers, w/ or w/out a national prefix formed by 00 or + and 2 digits*/
  PhoneNumberRegExp = '/^((00|\\+)\\d{2})?\\d{10}$/gm',
  /** Matches either the EmailRegExp or the PhoneNumberRegExp */
  PhoneNumberAndEmailRegExp = '/^(((([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))|(((00|\\+)\\d{2})?\\d{10}))$/gm',
  AlphabeticRegExp = '/^[a-zA-Z\\s]+$/',
  AlphanumericRegExp = '/^[a-zA-Z0-9\\s]+$/',
}
