import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

export const useBeforeUnload = (enabled: boolean) => {
  const {t} = useTranslation();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = t('GenericCloseModal.text');
    };

    if (enabled) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [enabled, t]);
};
