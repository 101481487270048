import {useCallback, useMemo} from 'react';
import {AnonymousRoutes} from '../../navigation';
import {SSOProps} from './interfaces';

export const useSSO = ({prompt = 'none'}: SSOProps) => {
  const SSOUri = useMemo(async () => {
    // Generate SSO Redirect URL
    const SSOUrl = new URL(process.env.REACT_APP_KERING_SSO_URL + '/authorize');
    const SSOQueryObject = {
      redirect_uri: window.location.origin + AnonymousRoutes.KERING_AUTH,
      scope: process.env.REACT_APP_KERING_SSO_SCOPE ?? '',
      client_id: process.env.REACT_APP_KERING_SSO_CLIENTID ?? '',
      response_type: 'code',
      prompt: prompt,
    };
    SSOUrl.search = new URLSearchParams(SSOQueryObject).toString();
    return SSOUrl;
  }, [prompt]);

  const goToSSO = useCallback(async () => {
    window.location.assign(await SSOUri);
  }, [SSOUri]);

  return {goToSSO};
};
