import {useCallback} from 'react';
import axios, {AxiosError} from 'axios';
import {useTranslation} from 'react-i18next';
import {ServerErrorResponse} from '../../definitions/Error';
import {useToast} from '../../hooks';
import {GenericError} from './definitions';

export const useError = () => {
  const {t} = useTranslation();
  const {showErrorToast} = useToast();

  const getErrorMessage = useCallback(
    (error: GenericError) => {
      if (axios.isAxiosError(error)) {
        const serverError = error as AxiosError<ServerErrorResponse>;
        const serverErrorMessage = serverError.response?.data?.message;

        // Set type to any[] otherwise i18next will throw a warning
        let translationKey: any[] = ['Errors.genericMessage'];

        if (serverErrorMessage) {
          translationKey = [
            `Errors.Server.${serverErrorMessage}`,
            ...translationKey,
          ];
        }

        return t(translationKey);
      }

      if (error instanceof Error) {
        return error.message;
      }

      return t('Errors.genericMessage');
    },
    [t],
  );

  const showError = useCallback(
    (error: GenericError) => {
      const errorMessage = getErrorMessage(error);

      showErrorToast({
        message: errorMessage,
      });
    },
    [getErrorMessage, showErrorToast],
  );

  return {
    getErrorMessage,
    showError,
  };
};
