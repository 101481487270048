import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {AppointmentSlotStyleProps} from '../../components/molecules/AppointmentSlot/interfaces';

export const useAppointmentVariantText = ({
  appointmentType,
  appointmentStatus,
  isPadding = false,
}: AppointmentSlotStyleProps) => {
  const {t} = useTranslation();

  const variantText = useMemo(
    () => ({
      PERSON: t('AppointmentCard.variantText.presence'),
      ONLINE: t('AppointmentCard.variantText.online'),
      PENDING: t('AppointmentCard.variantText.pending'),
    }),
    [t],
  );

  if (!appointmentType || isPadding) {
    return '';
  }

  if (appointmentStatus === 'RESCHEDULED') {
    return variantText.PENDING;
  }

  return variantText[appointmentType];
};
